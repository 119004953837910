import React, { DragEvent, useState } from 'react';
import './DragAndDrop.scss';

interface IProps {
  onFileDrop: (file: File) => void;
  loading: boolean;
}

const DragAndDrop: React.FC<IProps> = (props) => {
  // useState automatically infers a type of boolean here
  const [inDropZone, setInDropZone] = useState(false);
  // useState's type can be manually set, which will cause errors if the incorrect type is used
  const [dropIndex, setDropIndex] = useState<number>(0);

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(true);
    setDropIndex(dropIndex + 1);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const newDropIndex = dropIndex - 1;
    setDropIndex(newDropIndex);
    if (newDropIndex === 0) {
      setInDropZone(false);
    }
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(false);
    setDropIndex(0);

    if (props.loading) {
      return;
    }

    // Ensure that only one file has been dragged
    if (e.dataTransfer.files.length === 1) {
      const file = e.dataTransfer.files[0];
      props.onFileDrop(file);
    } else {
      alert('Only one file can uploaded at a time');
    }
    e.dataTransfer.clearData();
  };

  let classNames = 'drag-and-drop';
  if (inDropZone) {
    classNames += ' dragging';
  }

  return (
    <div
      className={classNames}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}>
      {props.children}
    </div>
  );
};

export default DragAndDrop;
