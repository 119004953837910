import * as Excel from 'exceljs';
import moment from 'moment';
import { IPricingResponse, IProgramLevel } from '../models/pricing-response';
import { MulesoftService } from './mulesoft-service';

/** This is the data we need to read from the input excel file */
interface IPersonData {
  name: string;
  tier: number;
  oldestDateOfBirth: string; // YYYY-MM-DD
  zipCode: string;
  state: string;
  maritalStatus: string;
  /** Must be at least 2020-09-01 */
  timeAt?: string; // YYYY-MM-DD
}

// This is the data that needs to be in the output quote file
interface IPersonQuote {
  name: string;
  oldestDateOfBirth: string;
  programLevels: IProgramLevel[];
}

// This is the old shape of the output for the excel file. When changing to the above format
// Typescript immediately showed errors to indicate invalid data/properties.
// interface IPersonQuote {
//   name: string;
//   oldestDateOfBirth: string;
//   ahp3000: number;
//   ahp6000: number;
//   ahp9000: number;
//   ahp12000: number;
// }

// TS: Typescript supports union types that indicate a variable can be one of any number of types
type HouseholdCount = '1' | '2' | '3+';
type Tier = 1 | 2 | 3;

export class PricingService {
  static async readExcelFile(file: File): Promise<Excel.Workbook> {
    const reader = new FileReader();
    const workbook = await new Promise<Excel.Workbook>((res, rej) => {
      reader.onload = async (evt) => {
        const wb = new Excel.Workbook();
        await wb.xlsx.load(evt.target?.result as Buffer);
        res(wb);
      };
      reader.readAsArrayBuffer(file);
    });
    return workbook;
  }

  static async processCensusFile(file: File): Promise<void> {
    try {
      const wb = await this.readExcelFile(file);
      await this.processCensusRows(wb);
    } catch (err) {
      throw err;
    }
  }

  static async processCensusRows(wb: Excel.Workbook): Promise<void> {
    try {
      const ws = wb.worksheets[0];
      const people: IPersonData[] = [];

      if (ws.rowCount > 184) {
        throw Error(`Error: Census file can only have a maximum of 180 entries but there are ${ws.rowCount - 4}`);
      }

      for (let rowIndex = 5; rowIndex <= ws.rowCount; rowIndex++) {
        const row = ws.getRow(rowIndex);
        if (!row.values.length) {
          break;
        }

        const zipCell = row.getCell(1);
        const zip = zipCell.text;
        const zipPadded = zip.padStart(5, '0');
        if (!zip || !RegExp(/\d{5}?/).test(zipPadded)) {
          throw Error(`Error in cell ${zipCell.address}: Zip code must be exactly 5 digits long`);
        }

        const stateCell = row.getCell(2);
        const state = stateCell.text;
        const stateValues: string = stateCell.dataValidation?.formulae[0];
        if (state.length !== 2 || stateValues?.search(state) === -1) {
          throw Error(`Error in cell ${stateCell.address}: State (${state}) is an invalid value`);
        }

        const nameCell = row.getCell(3);
        const name = nameCell.text;
        if (!name) {
          throw Error(`Error in cell ${nameCell.address}: Name is required`);
        }

        const employeeDOBCell = row.getCell(4);
        const employeeDOB = moment.utc(employeeDOBCell.value as Date);
        if (!employeeDOB.isValid()) {
          throw Error(`Error in cell ${employeeDOBCell.address}: Invalid date`);
        }

        const spouseDOBCell = row.getCell(5);
        const spouseDOB = spouseDOBCell.text ? moment.utc(spouseDOBCell.value as Date) : null;
        if (spouseDOB && !spouseDOB.isValid()) {
          throw Error(`Error in cell ${spouseDOBCell.address}: Invalid date`);
        }

        // Determine the oldest date of birth between the employee and their spouse, if any
        const oldestDOB = spouseDOB && spouseDOB.isBefore(employeeDOB) ? spouseDOB : employeeDOB;

        // Determine the tier based on the household count
        const householdCountCell = row.getCell(6);
        // TS: Here we use a union type to indicate that household count must be 1, 2, or 3+
        // We must cast .text to HouseholdCount because we can't just assign any string to HouseholdCount
        // Any future reference to householdCount knows the value must be one of these 3 strings
        const householdCount: HouseholdCount = householdCountCell.text as HouseholdCount;
        if (['1', '2', '3+'].indexOf(householdCount) === -1) {
          throw Error(`Error in cell ${householdCountCell.address}: Invalid household count. Must be 1, 2, or 3+.`);
        }
        const tier: Tier = parseInt(householdCount) as Tier;

        const maritalStatusCell = row.getCell(7);
        const maritalStatus = maritalStatusCell.text;
        if (!maritalStatus || ['Single', 'Married', 'Divorced', 'Widowed'].indexOf(maritalStatus) === -1) {
          throw Error(
            `Error in cell ${maritalStatusCell.address}: Invalid marital status. Must be Single, Married, Divorced, or Widowed.`
          );
        }

        people.push({
          name: name,
          zipCode: zip,
          state: state,
          oldestDateOfBirth: oldestDOB.format('YYYY-MM-DD'),
          maritalStatus: maritalStatus,
          tier: tier,
        });
      }

      const promises = people.map((person, i) => {
        return this.getPricingForPerson(person, i + 5);
      });
      const quotes = await Promise.all(promises);

      // Generate output file
      this.outputQuote(quotes);
    } catch (err) {
      throw err;
    }
  }

  static async getPricingForPerson(person: IPersonData, row: number): Promise<IPersonQuote> {
    try {
      const url = '/pricing-api/v1/prices/medishare';
      const pricing: IPricingResponse = await MulesoftService.get(url, person);
      const ms3Program = pricing.programs.find((p) => p.name === 'MS3');
      const cs1Program = pricing.programs.find((p) => p.name === 'CS1');

      const programLevels: IProgramLevel[] = [];
      if (ms3Program) {
        ms3Program.programLevels.forEach(ms3Prog => {
          ms3Prog.name = `${ms3Prog.name} Complete`
        });
        programLevels.push(...ms3Program.programLevels);
      }
      if (cs1Program) {
        cs1Program.programLevels.forEach(cs1Prog => {
          cs1Prog.name = `${cs1Prog.name} Complete`
        });
        programLevels.push(...cs1Program.programLevels);
      }

      if (!programLevels) {
        throw Error(
          `Error in row ${row}: Could not find MS3 program in pricing engine response. Is this person eligible for MS3?`
        );
      }

      // TS enforces the IPersonQuote of this returned object due to the type
      // annotation at the end of the function declaration
      return {
        name: person.name,
        oldestDateOfBirth: person.oldestDateOfBirth,
        programLevels: programLevels,
      };
    } catch (err) {
      throw err;
    }
  }

  static async outputQuote(quotes: IPersonQuote[]): Promise<void> {
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Quote');
    ws.properties.defaultRowHeight = 18;
    ws.views = [{}, { showGridLines: false }];

    const thinBorder: Partial<Excel.Borders> = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const headerBorder: Partial<Excel.Borders> = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'double' },
      right: { style: 'thin' },
    };

    const currencyFormat = '"$"#,##0';
    const colors = {
      yellow: 'FFFFF2CC',
      green: 'FFC6E0B4',
      orange: 'FFF4B084',
      blue: 'FFBDD7EE',
      grey: 'FFD0CECE',
      white: 'FFFFFFFF',
    };

    // Get a list of unique program names
    const programLevelNames = [
      ...new Set(quotes.map((quote) => quote.programLevels.map((level) => level.name)).flat()),
    ];
    const programLevelStartColumn = 3;
    const programLevelEndColumn = programLevelStartColumn + programLevelNames.length - 1;
    const selectedAhpColumn = ws.getColumn(programLevelEndColumn + 1);
    const monthlyShareColumn = ws.getColumn(programLevelEndColumn + 2);
    const totalsTableHeaderColumn = ws.getColumn(programLevelEndColumn + 4);
    const totalsTableValueColumn = ws.getColumn(programLevelEndColumn + 5);

    // Header
    const headerCell = ws.getCell('A1');
    headerCell.value = 'Medi-Share Employer Programs Quote';
    headerCell.style = {
      alignment: { horizontal: 'center', vertical: 'middle' },
      font: { size: 20, bold: true, name: 'Arial Rounded MT Bold' },
      border: thinBorder,
    };
    ws.mergeCells('A1:H1');

    // Calculated date
    const calcDateCell = ws.getCell('A2');
    calcDateCell.value = `Calculated on ${moment.utc().format('MMMM D, YYYY')}`;
    calcDateCell.font = { italic: true };

    // Share Header
    const shareHeaderCell = ws.getCell('C2');
    shareHeaderCell.value = 'Monthly Share Amounts';
    shareHeaderCell.alignment = { horizontal: 'center' };
    shareHeaderCell.font = { bold: true };
    const shareHeaderEndColLetter = String.fromCharCode('C'.charCodeAt(0) + programLevelNames.length - 1);
    const mergeStartLetter = String.fromCharCode(shareHeaderEndColLetter.charCodeAt(0) + 1);
    const mergeEndLetter = String.fromCharCode(shareHeaderEndColLetter.charCodeAt(0) + 2);
    ws.mergeCells(`C2:${shareHeaderEndColLetter}2`);
    ws.mergeCells(`${mergeStartLetter}2:${mergeEndLetter}2`);

    // Table header row
    const headerRow = ws.getRow(3);
    headerRow.getCell(1).value = 'Name';
    headerRow.getCell(2).value = 'DOB';

    for (let i = 0; i < programLevelNames.length; i++) {
      const programLevelName = programLevelNames[i];
      const programLevelColumn = i + programLevelStartColumn;
      headerRow.getCell(programLevelColumn).value = programLevelName;
    }

    headerRow.getCell(selectedAhpColumn.number!).value = 'AHP Selected';
    headerRow.getCell(monthlyShareColumn.number!).value = 'Monthly Share';
    headerRow.eachCell((cell, colNum) => {
      cell.font = { bold: true };
      cell.border = headerBorder;
      cell.alignment = { horizontal: 'center' };
      cell.numFmt = currencyFormat;
    });

    let lastRowProcessed = 0;
    quotes.forEach((quote, i) => {
      const row = ws.getRow(i + 4);
      row.height = 18;
      const fillStyle: Excel.Fill = i % 2 === 1 ? solidFill(colors.grey) : solidFill(colors.white);

      const nameCell = row.getCell(1);
      nameCell.value = quote.name;
      nameCell.border = thinBorder;
      nameCell.fill = fillStyle;

      const dobCell = row.getCell(2);
      dobCell.value = moment(quote.oldestDateOfBirth).toDate();
      dobCell.border = thinBorder;
      dobCell.fill = fillStyle;

      for (let i = 0; i < programLevelNames.length; i++) {
        const programLevelName = programLevelNames[i];
        const programLevelColumn = i + programLevelStartColumn;
        const quoteCell = row.getCell(programLevelColumn);
        const programLevelAmount = quote.programLevels.find((pl) => pl.name === programLevelName)!.totalAmount;

        quoteCell.numFmt = currencyFormat;
        quoteCell.value = programLevelAmount;
        quoteCell.border = thinBorder;
        quoteCell.alignment = { horizontal: 'right' };
        quoteCell.fill = fillStyle; // solidFill(colors.yellow);
      }

      const uniqueQuoteLevels = quote.programLevels.map((pl) => pl.name).join(',');
      const selectedAhpCell = row.getCell(selectedAhpColumn.number!);
      selectedAhpCell.dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [`"${uniqueQuoteLevels}"`],
      };
      selectedAhpCell.numFmt = currencyFormat;
      selectedAhpCell.value = null;
      selectedAhpCell.border = thinBorder;
      selectedAhpCell.fill = fillStyle;

      const monthlyShareCell = row.getCell(monthlyShareColumn.number!);
      const programLevelEndLetter = ws.getColumn(programLevelEndColumn).letter;
      const formula = `IFERROR(OFFSET(${dobCell.address}, 0, MATCH(${selectedAhpCell.address}, $C$3:$${programLevelEndLetter}$3, 0)),0)`;
      monthlyShareCell.numFmt = currencyFormat;
      monthlyShareCell.value = { formula } as any;
      monthlyShareCell.border = thinBorder;
      monthlyShareCell.fill = fillStyle;

      lastRowProcessed = i + 4;
    });

    // Uncomment below to activate MDF Disclaimer
    // --- Add the [MDF Disclaimer] a few rows after the last row of results. ---
    const disclaimerLine = lastRowProcessed + 3;
    const row = ws.getRow(disclaimerLine);
    row.height = 60;
    const fillStyle: Excel.Fill = disclaimerLine % 2 === 1 ? solidFill(colors.grey) : solidFill(colors.white);

    const disclaimerCell = row.getCell(1);
    disclaimerCell.value =
      'To receive the 10% discount, you must select January 1, 2024, as the effective date for the Medi-Share Complete program. This offer is not applicable to Medi-Share 65+ or Medi-Share Value. The 10% discount will be applied to your monthly share portion for the first 12 months and does not extend to Health Partnership or Account fees. You are free to cancel your participation in the Medi-Share program at any time without incurring a penalty. Share amounts are subject to change at any time during the discount period. Additional restrictions may apply. © 2023 Christian Care Ministry. All rights reserved.';
    disclaimerCell.border = thinBorder;
    disclaimerCell.fill = fillStyle;

    disclaimerCell.style = {
      alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
      border: thinBorder,
    };
    ws.mergeCells(`A${disclaimerLine}:J${disclaimerLine + 9}`);
    // --- end MDF Disclaimer logic ---

    // Totals table
    const totalTableHeaderCell1 = ws.getCell(`${totalsTableHeaderColumn.letter}3`);
    const totalTableHeaderCell2 = ws.getCell(`${totalsTableValueColumn.letter}3`);

    totalTableHeaderCell1.value = 'Totals Based on AHP';
    totalTableHeaderCell1.font = { size: 16, bold: true };
    totalTableHeaderCell1.alignment = { horizontal: 'center' };
    totalTableHeaderCell1.border = border('thick', 'thick', 'thick', 'thin');
    ws.mergeCells(`${totalTableHeaderCell1.address}:${totalTableHeaderCell2.address}`);

    const totalTableStartRow = 4;
    const totalTableEndRow = totalTableStartRow + programLevelNames.length;
    for (let i = 0; i < programLevelNames.length; i++) {
      const programLevelName = programLevelNames[i];
      const programLevelColumnNum = i + programLevelStartColumn;
      const headerCellRowNum = i + totalTableStartRow;

      const headerCell = ws.getCell(`${totalsTableHeaderColumn.letter}${headerCellRowNum}`);
      headerCell.value = programLevelName;
      headerCell.numFmt = currencyFormat;
      // headerCell.fill = solidFill(colors.yellow);
      headerCell.border = border('thick', 'thin', 'thin', 'thin');
      headerCell.alignment = { horizontal: 'center' };
      headerCell.font = { bold: true };

      const valueCell = ws.getCell(`${totalsTableValueColumn.letter}${headerCellRowNum}`);
      const programLevelColumnLetter = ws.getColumn(programLevelColumnNum).letter;
      valueCell.value = {
        formula: `SUM(${programLevelColumnLetter}:${programLevelColumnLetter})`,
      } as any;
      valueCell.numFmt = currencyFormat;
      valueCell.border = border('thin', 'thin', 'thick', 'thin');
    }

    const sharedSelectedTotalHeaderCell = ws.getCell(`${totalsTableHeaderColumn.letter}${totalTableEndRow}`);
    sharedSelectedTotalHeaderCell.value = 'Share Selected';
    sharedSelectedTotalHeaderCell.border = border('thick', 'thin', 'thin', 'thick');
    sharedSelectedTotalHeaderCell.alignment = { horizontal: 'center' };
    sharedSelectedTotalHeaderCell.font = { bold: true };

    const sharedSelectedTotalValueCell = ws.getCell(`${totalsTableValueColumn.letter}${totalTableEndRow}`);
    sharedSelectedTotalValueCell.value = {
      formula: `SUM(${monthlyShareColumn.letter}:${monthlyShareColumn.letter})`,
    } as any;
    sharedSelectedTotalValueCell.numFmt = currencyFormat;
    sharedSelectedTotalValueCell.border = border('thin', 'thin', 'thick', 'thick');

    // Column widths
    ws.getColumn('A').width = 24;
    selectedAhpColumn.width = 12;
    monthlyShareColumn.width = 12;
    
    // Column length adjustment for program names
    for (let index = 3; index < (programLevelEndColumn + 1); index++) {
      ws.getColumn(index).width = 15.5;
    }

    ws.getColumn(programLevelEndColumn + 3).width = 3;
    totalsTableHeaderColumn.width = 17;
    totalsTableValueColumn.width = 12;
    ws.getColumn(programLevelEndColumn + 6).width = 3;

    // Row heights
    ws.getRow(1).height = 55;
    ws.getRow(8).height = 18;

    const buffer = await wb.xlsx.writeBuffer();

    // Download the file
    var blob = new Blob([buffer], { type: 'application/pdf' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'Groups Quote.xlsx');
    link.click();

    function border(
      left: Excel.BorderStyle,
      top: Excel.BorderStyle,
      right: Excel.BorderStyle,
      bottom: Excel.BorderStyle
    ) {
      return {
        left: { style: left },
        top: { style: top },
        right: { style: right },
        bottom: { style: bottom },
      };
    }

    function solidFill(color: string): Excel.Fill {
      return { type: 'pattern', pattern: 'solid', fgColor: { argb: color } };
    }
  }
}
