import React, { useState, ChangeEvent } from 'react';
import './UploadScreen.scss';
import DragAndDrop from '../../components/DragAndDrop/DragAndDrop';
import { PricingService } from '../../services/pricing-service';
import uploadIcon from './upload-icon.png';

const UploadScreen: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // React provides a ChangeEvent type that gives intellisense on what is available
  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    event.target.value = '';
    await processFile(file);
  };

  const processFile = async (file: File) => {
    setError('');
    if (!file?.name.endsWith('.xlsx') && !file?.name.endsWith('.xls')) {
      alert('Only excel files can be uploaded (.xls or .xlsx)');
      return;
    }

    try {
      setLoading(true);
      await PricingService.processCensusFile(file);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-screen">
      <h2>Upload a groups census file</h2>
      <a className="download-link" href={process.env.PUBLIC_URL + '/files/census-template.xlsx'} download>
        Download the census template
      </a>

      <DragAndDrop onFileDrop={processFile} loading={loading}>
        <input
          className="upload-input"
          id="census-file"
          name="census-file"
          type="file"
          accept=".xls,.xlsx"
          onChange={onFileChange}
          disabled={loading}
        />

        {/* React use htmlFor instead of the native for attribute. TS shows an error when using for while JS does not. */}
        <label htmlFor="census-file">
          <div className="col col-center">
            <img className="upload-icon" src={uploadIcon} alt="Upload" />
            <div className="upload-message text-center">
              Drag and drop a Groups Census file or click to pick from your hard drive
            </div>
          </div>
        </label>
      </DragAndDrop>

      {loading && <div className="loading-text">Loading...</div>}
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default UploadScreen;
