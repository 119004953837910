import axios from 'axios';

const mulesoftUrl = process.env.REACT_APP_MULE_API_URL;
const mulesoftId = process.env.REACT_APP_MULE_API_CLIENT_ID;
const mulesoftSecret = process.env.REACT_APP_MULE_API_CLIENT_SECRET;
const mulesoftAuthHeader = 'Basic ' + btoa(`${mulesoftId}:${mulesoftSecret}`);

export class MulesoftService {
  static async get(endpoint: string, params = {}) {
    try {
      const { data } = await axios.get(`${mulesoftUrl}${endpoint}`, {
        headers: {
          Authorization: mulesoftAuthHeader,
        },
        params: params,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
